.swiper-pagination-bullets {
  &.swiper-pagination-horizontal {
    @apply flex justify-center items-center w-auto sm:w-full;

    &:not(.swiper-pagination-relative) {
      @apply bottom-16 right-32 left-[initial] md:right-8 md:bottom-8 sm:bottom-7 sm:right-[initial];

      &.swiper-pagination-left {
        @apply bottom-10 left-10 right-[initial] md:right-8 md:bottom-8 sm:w-full sm:bottom-14 sm:left-0;
      }
    }

  }
}

.swiper-pagination-bullet {
  @apply w-[7px] h-[7px] empty:min-h-0 opacity-100;

  &.swiper-pagination-bullet-active {
    @apply w-[11px] h-[11px];
  }
}

.swiper-pagination {
  &.swiper-pagination-relative {
    @apply relative top-[initial] bottom-[initial] left-[initial] right-[initial] w-auto;
  }

  .swiper-pagination-bullet {
    @apply bg-white;

    &.swiper-pagination-bullet-active {
      @apply bg-white/[0.33];
    }
  }

  &.swiper-pagination-primary .swiper-pagination-bullet {
    @apply bg-primary/30;

    &.swiper-pagination-bullet-active {
      @apply bg-primary;
    }
  }

  &.swiper-pagination-outline .swiper-pagination-bullet {
    @apply w-[11px] h-[11px] bg-transparent ring-[1px] ring-white;

    &.swiper-pagination-bullet-active {
      @apply w-[11px] h-[11px] bg-white ring-[1px] ring-primary;
    }
  }
}
