.btn {
  @apply inline-flex justify-center items-center text-s font-bold tracking-[0.2em] uppercase no-underline text-[--btn-text-color] text-center text-balance sm:text-xs;

  &:where(:disabled, [disabled], .disabled) {
    @apply pointer-events-none after:content-none;
  }

  &:where(a, button):not(.btn-fill, .btn-outline, .btn-overlap) {
    @apply relative before:absolute before:-inset-2;
  }

  &:not(.btn-fill, .btn-outline) {
    :where(.icon-chevron-left, .icon-chevron-right) {
      @apply transition-transform duration-300;
    }

    &:hover {
      &:where(:not(:disabled, [disabled], .disabled)) {
        :where(.icon-chevron-left) {
          @apply -translate-x-[80%];
        }

        :where(.icon-chevron-right) {
          @apply translate-x-[80%];
        }
      }
    }
  }

  &:where(.btn-overlap) {
    @apply after:absolute after:inset-0 disabled:content-none;
  }

  &:where(.btn-underline) {
    @apply underline underline-offset-[0.6em] decoration-1;

    &:where(:not(:disabled, [disabled], .disabled)) {
      @apply hover:underline-offset-[calc(0.6em-1px)] hover:decoration-2;
    }
  }

  &:where(.btn-white) {
    @apply [--btn-text-color:theme(colors.white)];
  }

  &:where(.btn-primary) {
    @apply [--btn-text-color:theme(colors.primary)];
  }

  &:where(.btn-secondary) {
    @apply [--btn-text-color:theme(colors.secondary)];
  }

  &:where(.btn-tertiary) {
    @apply [--btn-text-color:theme(colors.tertiary)];
  }
}

.btn-fill, .btn-outline {
  @apply h-[3.625rem] px-10 bg-[--btn-bg-color] border border-[var(--btn-border-color,var(--btn-bg-color))] rounded-none font-medium transition-colors duration-150;

  &:where(:disabled, [disabled], .disabled) {
    @apply opacity-50;
  }
}

.btn-fill, .btn-outline:where(:not(:disabled, [disabled], .disabled)):hover {
  &:where(.btn-white) {
    @apply [--btn-bg-color:theme(colors.white)] [--btn-text-color:theme(colors.primary)];
  }

  &:where(.btn-primary) {
    @apply [--btn-bg-color:theme(colors.primary)] [--btn-text-color:theme(colors.white)];
  }

  &:where(.btn-secondary) {
    @apply [--btn-bg-color:theme(colors.secondary)] [--btn-text-color:theme(colors.white)];
  }

  &:where(.btn-tertiary) {
    @apply [--btn-bg-color:theme(colors.tertiary)] [--btn-text-color:theme(colors.white)];
  }
}

.btn-fill:where(:not(:disabled, [disabled], .disabled)):hover, .btn-outline {
  @apply [--btn-border-color:var(--btn-text-color)];
}

.btn-fill {
  &:where(:not(:disabled, [disabled], .disabled)) {
    @apply hover:[--btn-bg-color:transparent];

    &.btn-white {
      @apply hover:[--btn-text-color:theme(colors.white)];
    }

    &.btn-primary {
      @apply hover:[--btn-text-color:theme(colors.primary)];
    }

    &.btn-secondary {
      @apply hover:[--btn-text-color:theme(colors.secondary)];
    }

    &.btn-tertiary {
      @apply hover:[--btn-text-color:theme(colors.tertiary)];
    }
  }
}

.btn-outline {
  &:where(:not(:disabled, [disabled], .disabled)) {
    @apply hover:[--btn-border-color:var(--btn-bg-color)];
  }
}

.btn-icon {
  @apply first:mr-3.5 last:ml-3.5;

  &:where(svg) {
    @apply [&_[fill^="#"]]:fill-[--btn-text-color] [&_[stroke^="#"]]:stroke-[--btn-text-color];
  }
}
