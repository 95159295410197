.logo {
  --logo-ideal-area: 0;
  --logo-width: 0;
  --logo-height: 0;
  --logo-area: calc(var(--logo-width) * var(--logo-height));
  --logo-ratio: calc(var(--logo-ideal-area) / var(--logo-area));
  --logo-guess01: calc(calc(var(--logo-ratio) + calc(var(--logo-ratio) / var(--logo-ratio))) / 2);
  --logo-guess02: calc(calc(var(--logo-guess01) + calc(var(--logo-ratio) / var(--logo-guess01))) / 2);
  --logo-guess03: calc(calc(var(--logo-guess02) + calc(var(--logo-ratio) / var(--logo-guess02))) / 2);
  --logo-guess04: calc(calc(var(--logo-guess03) + calc(var(--logo-ratio) / var(--logo-guess03))) / 2);
  --logo-guess05: calc(calc(var(--logo-guess04) + calc(var(--logo-ratio) / var(--logo-guess04))) / 2);
  --logo-guess06: calc(calc(var(--logo-guess05) + calc(var(--logo-ratio) / var(--logo-guess05))) / 2);
  --logo-guess07: calc(calc(var(--logo-guess06) + calc(var(--logo-ratio) / var(--logo-guess06))) / 2);
  --logo-guess08: calc(calc(var(--logo-guess07) + calc(var(--logo-ratio) / var(--logo-guess07))) / 2);
  --logo-guess09: calc(calc(var(--logo-guess08) + calc(var(--logo-ratio) / var(--logo-guess08))) / 2);
  --logo-sqrt: var(--logo-guess09);
  max-width: calc(var(--logo-width) * var(--logo-sqrt) / 2 * 1px);
}
