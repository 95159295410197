.leaflet-pane {
  @apply z-[1];
}

.leaflet-top, .leaflet-bottom {
  @apply z-[3];
}

.leaflet-control {
  @apply z-[2];
}

.leaflet-attribution-flag {
  @apply !hidden;
}
