@import 'bigger-picture/dist/bigger-picture';
@import 'image-compare-viewer/dist/image-compare-viewer.min';
@import 'leaflet/dist/leaflet';
@import 'swiper/swiper.min';
@import 'swiper/modules/effect-fade.min';
@import 'swiper/modules/free-mode.min';
@import 'swiper/modules/navigation.min';
@import 'swiper/modules/pagination.min';
@import 'vidstack/player/styles/base';
@import 'vidstack/player/styles/plyr/theme';
@import 'https://use.typekit.net/wat4ljq.css';

@config '../../../../tailwind/application/config.js';

@media print {
  @page {
    margin-top: 20mm;
    margin-bottom: 20mm;
    margin-left: 24mm;
    margin-right: 24mm;
  }
}

:root {
  --scrollbar-width: 0px;

  --breakpoint-2xl: theme(screens.2xl.max);
  --breakpoint-xl: theme(screens.xl.max);
  --breakpoint-lg: theme(screens.lg.max);
  --breakpoint-md: theme(screens.md.max);
  --breakpoint-sm: theme(screens.sm.max);
  --breakpoint-xs: theme(screens.xs.max);

  --container-columns: 12;
  --container-gutter: 1.5rem; /* 24px */
  --container-offset: 4.5rem; /* 72px */
  --container-default-width: 81rem; /* 1296px */
  --container-wide-width: 103.75rem; /* 1660px */
  --container-col-1: 5.375rem; /* 86px */
  --container-col-2: 12.25rem; /* 196px */
  --container-col-3: 19.125rem; /* 306px */
  --container-col-4: 26rem; /* 416px */
  --container-col-5: 32.875rem; /* 526px */
  --container-col-6: 39.75rem; /* 636px */
  --container-col-7: 46.625rem; /* 746px */
  --container-col-8: 53.5rem; /* 856px */
  --container-col-9: 60.375rem; /* 966px */
  --container-col-10: 67.25rem; /* 1076px */
  --container-col-11: 74.125rem; /* 1186px */

  --header-height: 9.375rem;

  --plyr-border-radius: 0px;
  --plyr-color-main: theme(colors.secondary);

  @media screen(lg) {
    --container-offset: 3rem;
  }

  @media screen(md) {
    --header-height: 7.5rem;
  }

  @media screen(sm) {
    --container-gutter: 0.75rem;
    --container-offset: 1.375rem;
  }
}

:focus-visible {
  &:where(:not(input, select, textarea), input[type="submit"]) {
    @apply focus-visible:outline-2 focus-visible:outline-secondary focus-visible:outline-offset-4;
  }
}

[role="link"] {
  @apply cursor-pointer;
}

html {
  @apply antialiased print:[zoom:0.75];
}

body {
  @apply font-body font-light text-base text-primary;
}
