.form-section + .form-section {
  @apply mt-20 sm:mt-10;
}

.form-grid {
  @apply grid grid-cols-[repeat(var(--form-grid-columns,var(--container-columns)),minmax(0,1fr))] gap-x-[--container-gutter] gap-y-6;

  :where(& > .form-grid, & > .form-actions, & > .form-group) {
    @apply col-span-full;
  }
}

.form-actions {
  @apply mt-2 xs:grid xs:gap-y-6;
}
