[type="submit"] {
  @apply cursor-pointer;
}

[type="text"],
[type="email"],
[type="url"],
[type="password"],
[type="number"],
[type="date"],
[type="datetime-local"],
[type="month"],
[type="search"],
[type="tel"],
[type="time"],
[type="week"],
[multiple],
textarea,
select {
  @apply block w-full px-5 py-3.5 bg-grey-light2 border border-grey-light2 text-m text-primary transition-colors duration-150 placeholder:text-grey-dark disabled:bg-grey-medium/50 disabled:text-primary/80 disabled:cursor-not-allowed hover:[&:not([disabled])]:border-grey-medium focus:border-tertiary focus:ring-0;

  &.form-invalid {
    @apply border-alert hover:border-alert;
  }
}

select {
  @apply pr-7;
  background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="10.827" height="6.082" viewBox="0 0 10.827 6.082"><path d="M5.063,0,0,5.063l.306.306L4.845.808V.543h.436V.808L9.843,5.369l.284-.306Z" transform="translate(10.474 5.729) rotate(180)" stroke="%23212c42" stroke-width="0.5"/></svg>');
  background-position: right 0.8125rem center;
  background-size: 0.5625rem 0.3125rem;

  &:has(option[value=""]:checked, option:not([value]):checked) {
    @apply text-grey-dark;
  }
}

[type="checkbox"], [type="radio"] {
  @apply focus:outline-none focus:ring-0 focus-visible:outline-2 focus-visible:outline-secondary focus-visible:outline-offset-2;
}

[type="checkbox"] {
  @apply block h-6 w-6 bg-no-repeat [&:not(:checked)]:border-tertiary disabled:opacity-50 disabled:bg-grey-medium/50 disabled:[&:not(:checked)]:border-tertiary/50;

  &:checked {
    background-image: url('application/icons/checkbox.svg');
  }
}

[type="radio"] {
  @apply block h-[1.375rem] w-[1.375rem] border-tertiary bg-no-repeat;

  &:checked {
    background-image: url('application/icons/radio.svg');
  }
}
