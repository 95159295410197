.form-control {
  @apply relative block w-full;
}

.form-field {
  &.no-label {
    @apply md-more:mt-[2.125rem];
  }
}

.form-field-check-box, .form-field-radio-button {
  @apply grid;

  :where(.form-field-check-box-content, .form-field-radio-button-content) {
    :where(label) {
      @apply text-base text-left font-light leading-[1.2];
    }
  }
}

.form-field-check-box {
  @apply grid-cols-[1.5rem_1fr] gap-x-3.5;

  :where(.form-field-check-box-content) {
    @apply flex flex-col items-start;

    :where(label) {
      @apply mt-0.5;
    }
  }
}

.form-field-radio-button {
  @apply grid-cols-[1.375rem_1fr] gap-x-3;

  :where(.form-field-radio-button-content) {
    @apply flex flex-col items-start;
  }
}
