.title-underline {
  @apply relative pb-[0.675em] after:absolute after:bottom-0 after:left-0 after:h-px after:w-[2.25em] after:bg-current;
}

.title-82 {
  @apply text-[3.75rem] leading-[0.92] text-balance md:text-[4.25rem] sm:text-[3rem]; /* default: 82px / 75px - md: 68px - sm: 48px */

  &:where(.title-underline) {
    @apply after:h-0.5;
  }
}

.title-60 {
  @apply text-[3.75rem] leading-[0.93] text-balance md:text-[3.5rem] sm:text-[2.75rem]; /* default: 60px / 56px - md: 56px - sm: 44px */

  &:where(.title-underline) {
    @apply after:h-0.5;
  }
}

.title-54 {
  @apply text-[3.375rem] leading-[1] text-balance md:text-[3.25rem] sm:text-[2.1875rem]; /* default: 54px / 54px - md: 52px - sm: 35px */

  &:where(.title-underline) {
    @apply after:h-0.5;
  }
}

.title-48 {
  @apply text-[3rem] leading-[1.125] text-balance sm:text-[2.25rem]; /* default: 48px / 54px - sm: 36px */

  &:where(.title-underline) {
    @apply after:h-0.5;
  }
}

.title-39 {
  @apply text-[2.4375rem] leading-[1.23] text-balance sm:text-[1.8125rem]; /* default: 39px / 48px - sm: 29px */

  &:where(.title-underline) {
    @apply after:h-0.5;
  }
}

.title-35 {
  @apply text-[2.1875rem] leading-[1.25] text-balance sm:text-[1.9375rem]; /* default: 35px / 43px - sm: 31px */

  &:where(.title-underline) {
    @apply after:h-0.5;
  }
}

.title-32 {
  @apply text-[2rem] leading-[1.25] text-balance sm:text-[1.625rem]; /* default: 32px / 40px - sm: 26px */

  &:where(.title-underline) {
    @apply after:h-0.5;
  }
}

.title-30 {
  @apply text-[1.875rem] leading-[1.23] text-balance sm:text-[1.5rem]; /* default: 30px / 37px - sm: 24px */

  &:where(.title-underline) {
    @apply after:h-0.5;
  }
}

.title-28 {
  @apply text-[1.75rem] leading-[1.25] text-balance sm:text-xxl; /* default: 28px / 35px - sm: 22px */
}

.title-25 {
  @apply text-[1.5625rem] leading-[1.24] text-balance sm:text-l; /* default: 25px / 31px - sm: 19px */
}
