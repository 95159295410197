.pagy {
  @apply relative inline-flex rounded-md shadow-sm -space-x-px;

  a {
    @apply h-full bg-white border-gray-300 text-gray-500 relative inline-flex items-center px-3 py-2 border text-s font-medium;

    &[href]:not([aria-disabled="true"]) {
      @apply hover:bg-gray-50;
    }

    &:not([href]), &[aria-disabled="true"] {
      &:not(.current, :first-child, :last-child) {
        @apply cursor-default text-opacity-50;
      }
    }

    &:first-child, &:last-child {
      @apply px-2.5 text-opacity-0;

      &[aria-disabled="true"] {
        &::before {
          @apply text-opacity-50;
        }
      }

      &::before {
        @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-gray-500;
      }
    }

    &:first-child {
      @apply rounded-l-md;
    }

    &:last-child {
      @apply rounded-r-md;
    }

    &.gap {
      @apply px-2;
    }

    &.current {
      @apply bg-indigo-100 text-indigo-600;
    }
  }
}
