.wysiwyg-article {
  @apply [--wysiwyg-width:min(var(--container-default-width),100vw-var(--scrollbar-width)-(var(--container-offset)*2))] w-[--wysiwyg-width] max-w-full mx-auto p-0;

  & > :where(h1, h2, h3, h4, h5, h6, p, ol, ul, blockquote) {
    @apply w-[--container-col-10] max-w-full mx-auto;
  }

  & > :where(p) {
    & > .btn {
      @apply sm:flex sm:w-fit;

      & + .btn {
        @apply md-more:ml-3 sm:mt-4;
      }
    }
  }

  h1 + *,
  h2 + *,
  h3 + *,
  h4 + *,
  h5 + *,
  h6 + *,
  p + *,
  ul + *,
  ol + *,
  figure + *,
  pre + *,
  dl + *,
  address + *,
  div + p,
  div + ul,
  div + ol,
  div + dl,
  div + pre,
  div + h1,
  div + h2,
  div + h3,
  div + h4,
  div + h5,
  div + h6,
  div + figure {
    &:not(
      [class*="mt-"],
      [class*="my-"],
      hr,
      blockquote,
      table,
      .form-container,
      .form-section,
      .form-grid,
      .form-group,
      .form-label,
      .form-field,
      .form-hint,
      .form-actions
    ) {
      @apply mt-6;
    }
  }

  h3 {
    &:where(:not([class*="text-"], [class*="title-"])) {
      @apply relative pb-[0.675em] text-[1.875rem] leading-[1.23] uppercase text-balance after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-[2.25em] after:bg-secondary;

      & + *:not([class*="mt-"], [class*="my-"]) {
        @apply mt-8;
      }
    }
  }

  h4 {
    &:where(:not([class*="text-"], [class*="title-"])) {
      @apply text-xxl uppercase;
    }
  }

  hr {
    @apply my-12 border-primary/15 first:mt-0 last:mb-0;
  }

  blockquote {
    @apply relative my-32 pt-9 text-[1.875rem] leading-[1.33] text-center text-pretty space-y-10 md:my-28 sm:my-24 sm:pt-7 sm:space-y-5 sm:text-[1.4375rem] sm:text-left;

    &::before, &::after {
      @apply content-[''] absolute top-0 w-[4.375rem] aspect-[1.13/1] opacity-[0.09] bg-contain sm:-top-5;
    }

    &::before {
      @apply left-0;
      background-image: url('application/icons/quote-left.svg');
    }

    &::after {
      @apply right-0 sm:content-none;
      background-image: url('application/icons/quote-right.svg');
    }

    p {
      @apply w-[--container-col-8] max-w-[calc(100%-10rem)] mx-auto sm:max-w-full;
    }

    cite {
      @apply block text-m not-italic;
    }
  }

  table {
    @apply w-full my-12 text-m text-left first:mt-0 last:mb-0;
  }

  tr {
    &:nth-child(even) {
      td {
        @apply bg-grey-light2;
      }
    }
  }

  th, td {
    @apply px-6 py-3.5 border-l border-primary/15 first:border-l-0;
  }

  th {
    @apply text-base font-bold border-b bg-grey-light2;
  }
}
