.container {
  @apply [--container-width:min(var(--container-default-width),100vw-(var(--container-offset)*2))] w-[--container-width] max-w-full mx-auto print:w-full;
}

.container-wide {
  @apply [--container-width:min(var(--container-wide-width),100vw-(var(--container-offset)*2))] w-[--container-width] max-w-full mx-auto print:w-full;
}

.container-maxi {
  @apply [--container-width:min(theme(maxWidth.screen-default),100vw-(var(--container-offset)*2))] w-[--container-width] max-w-full mx-auto print:w-full;
}

.container-full {
  @apply [--container-width:calc(100vw-(var(--container-offset)*2))] w-[--container-width] max-w-full mx-auto print:w-full;
}

.grid-container {
  @apply grid grid-cols-container gap-x-[--container-gutter] max-w-[--container-default-width];

  :where(& > .form-container) {
    @apply [--form-grid-columns:10] col-start-[calc(((var(--container-columns)-var(--form-grid-columns))*0.5)+1)] col-end-[span_var(--form-grid-columns)] md:[--form-grid-columns:var(--container-columns)];
  }
}
