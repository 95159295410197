.wysiwyg-article,
.wysiwyg-light,
.wysiwyg-variable {
  & > * {
    &:first-child {
      @apply mt-0;
    }

    &:last-child {
      @apply mb-0;
    }
  }

  & > :where(p) {
    @apply empty:min-h-[1.67em];
  }

  b, strong {
    @apply font-bold;
  }

  a {
    &:where(:not(.btn, [class*="leaflet-"])) {
      @apply text-tertiary font-[inherit] underline;
    }
  }

  ol, ul {
    &:where(:not([data-custom])) {
      @apply space-y-1.5;

      ol, ul {
        @apply mt-1.5;
      }
    }
  }

  ol {
    &:where(:not([data-custom])) {
      @apply pl-6 list-decimal;

      & > li {
        @apply pl-0;
      }
    }
  }

  ul {
    &:where(:not([data-custom])) {
      @apply pl-0 list-none;

      & > li {
        @apply relative pl-2.5 before:absolute before:top-[0.8em] before:left-0 before:h-1 before:w-1 before:rounded-full before:bg-current;
      }
    }
  }

  figure, iframe {
    @apply max-w-full;
  }

  img {
    &:where(:not(.icv__img)) {
      @apply max-w-full;
    }
  }

  .embed-responsive {
    @apply relative h-0 m-0 p-0 pb-[56.25%];

    iframe {
      @apply absolute top-0 left-0 h-full w-full;
    }
  }

  .align-left {
    @apply text-left;
  }

  .align-right {
    @apply text-right;

    .wysiwyg-image {
      @apply ml-auto;
    }

    figcaption {
      @apply ml-auto text-right;
    }
  }

  .align-justify {
    @apply text-justify;
  }

  .align-center {
    @apply text-center;
  }

  .wysiwyg-grid {
    @apply grid grid-cols-container gap-x-[--container-gutter] gap-y-6 mx-auto my-10 first:mt-0 last:mb-0 sm:my-6;

    .column-1 {
      @apply col-end-[span_1] sm:col-span-full;
    }

    .column-2 {
      @apply col-end-[span_2] sm:col-span-full;
    }

    .column-3 {
      @apply col-end-[span_3] sm:col-span-full;
    }

    .column-4 {
      @apply col-end-[span_4] sm:col-span-full;
    }

    .column-5 {
      @apply col-end-[span_5] sm:col-span-full;
    }

    .column-6 {
      @apply col-end-[span_6] sm:col-span-full;
    }

    .column-7 {
      @apply col-end-[span_7] sm:col-span-full;
    }

    .column-8 {
      @apply col-end-[span_8] sm:col-span-full;
    }

    .column-9 {
      @apply col-end-[span_9] sm:col-span-full;
    }

    .column-10 {
      @apply col-end-[span_10] sm:col-span-full;
    }

    .column-11 {
      @apply col-end-[span_11] sm:col-span-full;
    }

    .column-12 {
      @apply col-end-[span_12] sm:col-span-full;
    }
  }
}
