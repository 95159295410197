.icv__circle {
  @apply w-[5.8125rem] h-[5.8125rem] bg-white lg:w-[3.125rem] lg:h-[3.125rem];
}

.icv__control-line {
  @apply !w-[1px];
}

.icv__arrow-wrapper svg {
  @apply !w-3 !h-3;
}

.icv__arrow-wrapper svg path {
  stroke: #212C42;
}
