.form-label {
  :where(abbr) {
    @apply no-underline;
  }
}

.form-group {
  &.form-group-invalid {
    .form-hint {
      @apply text-alert;
    }
  }

  &.form-group-check-boxes, &.form-group-radio-buttons {
    & > .form-label {
      @apply block mb-2 text-base text-left font-light;
    }
  }

  &:where(
    :not(
      .form-group-boolean,
      .form-group-check-boxes,
      .form-group-radio-buttons
    )
  ) {
    :where(.form-label) {
      @apply block mb-1 text-base text-left font-light;
    }

    :where(.form-field) {
      @apply relative flex gap-x-2;

      :where(.form-field-addon) {
        @apply flex-none;
      }
    }
  }
}

.form-group-check-boxes-content, .form-group-radio-buttons-content {
  @apply grid gap-y-4;
}

.form-hint {
  @apply mt-1 text-s/[1.375] italic;
}
