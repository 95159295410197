.wysiwyg-light {
  @apply text-pretty;

  h1 + *,
  h2 + *,
  h3 + *,
  h4 + *,
  h5 + *,
  h6 + *,
  p + *,
  ul + *,
  ol + *,
  figure + *,
  pre + *,
  dl + *,
  address + *,
  div + p,
  div + ul,
  div + ol,
  div + dl,
  div + pre,
  div + h1,
  div + h2,
  div + h3,
  div + h4,
  div + h5,
  div + h6,
  div + figure {
    &:not([class*="mt-"], [class*="my-"]) {
      @apply mt-4;
    }
  }

  h3 {
    &:where(:not([class*="text-"], [class*="title-"])) {
      @apply relative pb-[0.675em] text-[1.5625rem] leading-[1.24] uppercase text-balance after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-[2.25em] after:bg-secondary;

      & + *:not([class*="mt-"], [class*="my-"]) {
        @apply mt-6;
      }
    }
  }

  h4 {
    &:where(:not([class*="text-"], [class*="title-"])) {
      @apply text-xxl uppercase;
    }
  }
}
