/* Entry point for your PostCSS build */
@import 'tailwindcss/base';
@import './application/base';

@import 'tailwindcss/components';
@import './application/components';
@import './wysiwyg/components';

@import 'tailwindcss/utilities';
@import './application/utilities';
